@charset "utf-8";

@import '../../variables/variables.less';
@import '../../style-guide/components/vue-universal-actionbox.less';
@import '../../style-guide/components/frequently-asked.less';
@import '../../components/facelift/animatedGraphs.less';

body {
	color: #1C222E;
}

h1,
.h1 {
	font-size: 56px;
	font-weight: 900;
	line-height: 72px;
	text-align: center;

	@media @mobile, @tablet {
		font-size: 2.75rem;
		line-height: 3rem;
		font-weight: 750;
	}
}

h2,
.h2 {
	font-size: 46px;
	font-weight: 900;
	line-height: 60px;
	text-align: center;

	@media @mobile, @tablet {
		font-size: 2.125rem;
		line-height: 2.75rem;
		font-weight: 750;
	}
}

h6,
.h6 {
	font-size: 20px;
	font-weight: 900;
	line-height: 26px;
	text-align: center;
}

.h7 {
	font-size: 16px;
	font-weight: 900;
	line-height: 22px;
	text-align: left;
}

.body-1 {
	font-size: 20px;
	font-weight: 400;
	line-height: 26px;
	text-align: center;
}

.body-2 {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
}

.body-3 {
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	text-align: center;

	@media @mobile, @tablet {
		text-align: left;
	}
}

.body-4 {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
}

.text-white {
	color: @White;
}

.text-center {
	text-align: center !important;

	@media @mobile, @tablet {
		text-align: left !important;
	}
}

.text-left {
	text-align: left !important;

	@media @mobile, @tablet {
		text-align: center !important;
	}
}

.text-gray3 {
	color: #697284;
}

.bg-off-white {
	background-color: #F2F8FF;
}

.bg-white {
	background-color: @White;
}

.shadows {
	box-shadow: 0px 40px 56px 0px rgba(30, 34, 42, 0.08);
}

.border-10 {
	border-radius: 10px;
}

.border-gray5 {
	border: 1px solid #CED4E0;
}

.mb-32 {
	margin-bottom: 32px;
}

.px-20 {
	@media @mobile, @tablet {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.link-underline {
	font-size: 14px;
	font-weight: 700 !important;
	line-height: 16px;
	letter-spacing: 0.6px;
	text-align: left;
	border-bottom: 1px solid !important;

	&:hover {
		border-bottom: none !important;
	}
}

.vue-action-box {
	.label {
		background-color: #D1EDDE !important;
	}

	.label-text {
		font-size: 14px;
		font-weight: 700;
		line-height: 16px;
		letter-spacing: 1.2px;
		text-align: center;
		color: #00783B !important;
		text-transform: unset !important;
	}
}

.hero .hero-hint a {
	color: @White;
	border-bottom: 1px solid @White;

	&:hover {
		border-bottom: none;
	}
}

.hero__headline {
	@media @mobile, @tablet {
		font-size: 2.75rem;
		line-height: 3rem;
		font-weight: 750;
	}
}

.hero__content {
	.actionboxes-list {
		border: 1px solid #CED4E0;
		border-radius: 10px 0 0 10px;
		border-right: none;
		background-color: #EDF0F7;
		display: block;
		align-content: unset;
	}

	.actionboxes-wrapper {
		border: 1px solid #CED4E0;
		border-radius: 0 10px 10px 0;
		border-left: none;
		padding: 3px;
		background-color: @White;
	}
}

.collapse-toggler-wrap a.collapse-toggler {
	border-bottom: 1px solid;

	&:hover {
		border-bottom: none;
	}
}

.bg-gray {
	background-color: #F2F8FF;
}

.bg-gray8 {
	background-color: #F7F9FC;
}

#automatic-maintenance,
#speedup,
#bloatware,
#free-up-storage,
#update-apps-storage {

	h2,
	.h2 {
		@media @mobile, @tablet {
			text-align: center;
		}
	}

	p {
		br {
			:not([lang^="en-"]) & {
				display: none;
			}
		}

		&:not(.body-4) {
			@media @mobile, @tablet {
				text-align: center;
			}
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	.media-wrapper {
		margin-top: 3.5rem !important;

		h2,
		p {
			@media @desktop {
				text-align: left;
			}
		}

		h2 {
			margin-bottom: 1rem;
		}
	}
}

#speedup {
	@media @mobile, @tablet {
		padding: 0;
	}
}

#try-7-days {
	@media @mobile, @tablet {
		padding-top: 3rem;
	}

	.text-center {
		@media @mobile, @tablet {
			text-align: center !important;
		}
	}

	.row {
		padding: 88px 132px;
		position: relative;

		@media @mobile, @tablet {
			padding: 20px;
		}
	}
}

#bottom-actionbox {
	margin: 88px auto;

	h2.text-center, p.text-center {
		@media @mobile, @tablet {
			text-align: center !important;
		}
	}

	.actionboxes-list {
		border: 1px solid #CED4E0;
		border-radius: 10px 0 0 10px;
		border-right: none;
		background-color: #EDF0F7;
	}

	.actionboxes-wrapper {
		border: 1px solid #CED4E0;
		border-radius: 0 10px 10px 0;
		border-left: none;
		padding: 3px;
	}
}

#numbers {
	padding: 0 0 5.5rem 0;

	.row-header {
		p {
			margin-bottom: 5.5rem;
		}
	}
}

#requirements {
	.container {
		@media @mobile, @tablet {
			padding-top: 0;
		}
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: .5rem;

		li {
			max-width: 100%;
			margin-right: 0;
			display: flex;
			gap: .5rem;

			img {
				margin-bottom: 0;
			}

			a {
				text-decoration: underline;
				color: #1C222E;

				:hover {
					text-decoration: none !important;
				}
			}
		}
	}
}

#faq {
	padding-top: 5.5rem;

	h2.text-center {
		@media @mobile, @tablet {
			text-align: center !important;
		}
	}

	.faq-container div.expand+div {
		max-height: fit-content;
	}

	.answer {
		color: #697284;

		@media @tablet, @desktop {
			padding: 0 128px 0 48px !important;
		}

		@media @mobile {
			padding: 0 24px 0 24px !important;
		}

		a,
		.signal-link {
			color: #1C222E;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		.text-left {
			@media @mobile {
				text-align: left !important;
			}

			p,
			li {
				@media @mobile {
					text-align: left !important;
					font-size: 16px;
					font-weight: 400;
					line-height: 22px;
				}
			}
		}
	}
}

.signal-link {
	color: #1C222E;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
		color: #1C222E;
	}
}

#blogposts {
	.container {
		@media @desktop {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.slider-controls.slider-sliderSliNotiSecSm-controls {
		display: block;
		position: absolute;
		top: 0;
		right: 10%;
	}

	.carousel-slider .tiny-slider .tns-inner:after {
		@media (min-width: 1325px) {
			right: -1760px;
		}
	}

	a.button.secondary span {
		border-bottom: none;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.tns-item {
		.button.secondary span {
			border-bottom: none;
			text-decoration: underline;
		}

		&:hover {
			.button.secondary span {
				text-decoration: none;
			}
		}
	}
}

.list-item {
	display: flex;
	gap: 1rem;
	align-items: flex-start;

	p {
		color: #697284;

		a {
			color: #697284;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.button {
	&.primary {
		&.blue {
			&.outline {
				&.icon-right {
					span {
						&::after {
							margin-left: 1rem;
							content: url(../../../i/page/avg-pctuneup-v2/icon-arrow-right-blue.svg);
						}

						&:hover {
							&::after {
								content: url(../../../i/page/avg-pctuneup-v2/icon-arrow-right-white.svg);
							}
						}
					}
				}
			}
		}

		&.outline {
			&.icon-win {
				&>SPAN {
					:not([lang^="en-"]) & {
						padding-left: 78px;

						@media @mobile {
							height: auto;
						}
					}
				}
			}
		}
	}

	&.secondary {
		&.blue {
			&.icon-right {
				span {
					&::after {
						margin-left: 1rem;
						content: url(../../../i/page/avg-pctuneup-v2/icon-arrow-right-blue.svg);
					}

					&:hover {
						&::after {
							content: url(../../../i/page/avg-pctuneup-v2/icon-arrow-right-white.svg);
						}
					}
				}
			}
		}
	}
}